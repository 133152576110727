import styled from 'styled-components';

export const Spacer = styled.div`
  height: 1rem;
`;

export const Paragraph = styled.p``;

export const Message = styled.label`
  line-height: 1.5;
  transform: translateY(1px);
  cursor: pointer;
`;

export const TextArea = styled.textarea`
  border: 1px solid #ddd;
  resize: none;
  width: 100%;
  padding: 0.5rem;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  line-height: 1rem;
  margin-left: 1rem;

  & > input[type='radio'] {
    margin-right: 1rem;
  }
`;

export const Container = styled.div`
  max-width: 85%;
  margin: auto;
`;
