import React, {Fragment, FunctionComponent, useState} from 'react';
import {Button} from '../Button';
import {Card, CardStyle} from '../Card';
import {Theme} from '../../theme/Theme';
import {
  CardOverlay,
  CardOverlayState,
  OwnershipCardFooter,
  StyledOwnershipCard,
} from './Owernship.styles';
import {
  cardSettings,
  OwnershipStatus,
  productMarketing,
} from './OwnershipCard.models';
import {ProductImage} from '../ProductImage';
import {FluidObject} from 'gatsby-image';
import {ButtonVariant} from '../Button/ButtonVariant';
import {LoadingIcon} from '../Icon/LoadingIcon';
import styled from 'styled-components';

const {colours} = Theme;

interface OwnershipCardProps {
  testID?: string;
  state: OwnershipStatus;
  image: FluidObject;
  productName: string;
  planId?: string;
  /** URL to the logo */
  logo: string;
  /** called when a user clicks to start a subscription */
  onStartSubscription: () => void;
  /** called when a user clicks to cancel a subscription, this is awaited till it returns a success for failed boolean */
  onCancelSubscription: (
    planId: string,
    softwareId: string
  ) => Promise<boolean>;
  /** called when a user clicks to reactivate a subscription, this is awaited till it returns a success for failed boolean */
  onReactivateSubscription: (planId: string) => Promise<boolean>;

  /** The number of months left in the term till you own the product */
  monthsRemaining?: number;
  nextBillingDate?: string;
  softwareId: string;
}

const AlternateOutlineButton = styled(Button)`
  :focus-visible {
    outline: #469dad solid 3px;
  }
`;

export const OwnershipCard: FunctionComponent<OwnershipCardProps> = ({
  state,
  testID,
  logo,
  image,
  productName,
  onStartSubscription,
  onCancelSubscription,
  onReactivateSubscription,
  monthsRemaining,
  nextBillingDate,
  softwareId,
  planId,
}) => {
  const noStatus = state === OwnershipStatus.NONE;
  const loadingStatus = state === OwnershipStatus.LOADING;

  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [overlayState, setOverlayState] = useState<CardOverlayState>(
    loadingStatus ? CardOverlayState.LOADING : CardOverlayState.CONFIRM
  );
  const settings = cardSettings.get(state) || {};
  const marketing = productMarketing.get(softwareId);
  const totalNumPayments = 15;
  const formattedBillingDate = (() => {
    if (!nextBillingDate) {
      return 'next billing date';
    }
    return new Date(nextBillingDate).toLocaleDateString(undefined, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  })();

  const handleSubscriptionAction = async (
    action: (planId: string, softwareId: string) => Promise<boolean>
  ) => {
    setOverlayState(CardOverlayState.LOADING);
    const success = await action(planId!, softwareId);
    if (!success) {
      setOverlayState(CardOverlayState.ERROR);
    } else {
      setShowOverlay(false);
    }
  };

  const handleActionButtonClick = () => {
    switch (state) {
      case OwnershipStatus.SUBSCRIBED:
        setOverlayState(CardOverlayState.CONFIRM);
        setShowOverlay(true);
        break;
      case OwnershipStatus.CANCELLED:
        setShowOverlay(true);
        handleSubscriptionAction(onReactivateSubscription);
        break;
      case OwnershipStatus.EXPIRED:
        onStartSubscription();
        break;
    }
  };

  return (
    <StyledOwnershipCard status={state} data-testid={testID}>
      <ProductImage
        logo={logo}
        product={image}
        onClick={noStatus ? onStartSubscription : undefined}
        greyScale={
          noStatus || state === OwnershipStatus.CANCELLED || loadingStatus
        }
      />
      <OwnershipCardFooter>
        <Card
          flexDirection="column"
          justifyContent="flex-start"
          cardStyle={
            noStatus || state === OwnershipStatus.CANCELLED
              ? CardStyle.SOLID
              : CardStyle.OUTLINE
          }
          colour={colours.greys[7]}
          height="100%"
          data-testid="plan-ownership"
        >
          {settings.info && (
            <Fragment>
              {state == OwnershipStatus.OWNED && (
                <div>
                  <strong>You own this plugin. </strong>
                  <br />
                  Plugin purchased with one time payment.
                </div>
              )}
              {state == OwnershipStatus.SUBSCRIBED && (
                <div>
                  {monthsRemaining && (
                    <>
                      <strong>Monthly payments completed:</strong>{' '}
                      {totalNumPayments - monthsRemaining}.
                      <br />
                    </>
                  )}
                  Unlimited access granted after {totalNumPayments} months.
                </div>
              )}
              {state == OwnershipStatus.CANCELLED && (
                <div>
                  <strong>Valid until:</strong> {formattedBillingDate}.<br />
                </div>
              )}
            </Fragment>
          )}
          {settings.button && (
            <AlternateOutlineButton
              data-testid="ownership-action-button"
              background={settings.button.background}
              color={settings.button.colour}
              onClick={handleActionButtonClick}
            >
              {settings.button.copy}
            </AlternateOutlineButton>
          )}
          {noStatus && marketing && (
            <Fragment>
              <span style={{paddingBottom: `${Theme.space[4]}px`}}>
                {marketing.marketingCopy}
              </span>
              <Button
                data-testid="purchase-button"
                variant={ButtonVariant.DARK}
                onClick={onStartSubscription}
              >
                {marketing.purchaseButtonCopy}
              </Button>
            </Fragment>
          )}
        </Card>
        <CardOverlay
          show={showOverlay || loadingStatus}
          state={overlayState}
          data-testid="card-overlay"
        >
          {overlayState === CardOverlayState.ERROR && (
            <Fragment>
              <span>
                There was a problem{' '}
                {state === OwnershipStatus.CANCELLED
                  ? 'reactivating'
                  : 'cancelling'}{' '}
                your subscription to {productName}, please try again.
              </span>
              <Button
                variant={ButtonVariant.DARK}
                onClick={() => {
                  if (state === OwnershipStatus.CANCELLED) {
                    setShowOverlay(false);
                  }
                  if (state == OwnershipStatus.SUBSCRIBED) {
                    setOverlayState(CardOverlayState.CONFIRM);
                  }
                }}
                data-testid="card-overlay-confirm-error"
              >
                Ok
              </Button>
            </Fragment>
          )}
          {overlayState === CardOverlayState.LOADING && (
            <LoadingIcon width="100%" />
          )}
          {overlayState === CardOverlayState.CONFIRM && (
            <Fragment>
              <span>
                Are you sure you want to cancel your monthly payments to{' '}
                {productName}?
              </span>
              <Button
                variant={ButtonVariant.DARK}
                onClick={() => handleSubscriptionAction(onCancelSubscription)}
                data-testid="card-overlay-confirm"
              >
                Yes, cancel my monthly payments
              </Button>
              <Button
                background={colours.red}
                variant={ButtonVariant.MONOCHROME}
                onClick={() => setShowOverlay(false)}
                data-testid="card-overlay-cancel"
              >
                No, keep my monthly payments
              </Button>
            </Fragment>
          )}
        </CardOverlay>
      </OwnershipCardFooter>
    </StyledOwnershipCard>
  );
};
