import React, {FunctionComponent} from 'react';
import {ProductImageContent} from '../../types/HomepageContent';
import {
  ProductImageWrapper,
  Background,
  Product,
  Logo,
} from './ProductImage.styles';

interface ProductImageProps extends ProductImageContent {
  /** if onclick is supplied the product image will have a rollover animation */
  onClick?: () => void;
  /** makes the images greyscale, if interactive will change to colour when you hover over the images*/
  greyScale?: boolean;
}

export const ProductImage: FunctionComponent<ProductImageProps> = ({
  product,
  logo,
  onClick,
  greyScale = false,
}) => (
  <ProductImageWrapper onClick={onClick} greyScale={greyScale}>
    <Background style={{position: 'absolute'}} fluid={product} />
    <Product fluid={product} />
    <Logo src={logo} />
  </ProductImageWrapper>
);
