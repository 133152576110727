import React, {FunctionComponent, useState} from 'react';
import {Modal, Button} from '@focusrite-novation/ampify-web-ui';
import {
  Spacer,
  Paragraph,
  Message,
  TextArea,
  Option,
  Container,
} from './CancellationReasonModal.styles';

export interface CancellationReasonModalProps {
  onClose(): void;
  onSubmit(type: Reason, comment: string): void;
}

export enum Reason {
  NONE = 'none',
  TOO_EXPENSIVE = 'too_expensive',
  TOO_COMPLICATED = 'too_complicated',
  DO_NOT_USE_IT = 'do_not_use_it',
  JUST_TESTING = 'just_testing',
  SOUNDS = 'sounds',
  WORKFLOW = 'workflow',
  FEATURES = 'features',
  NOT_COMPATIBLE = 'not_compatible',
  OTHER = 'other',
}

export interface CancellationReason {
  type: Reason;
  value: string;
}

const cancellationReasons: CancellationReason[] = [
  {type: Reason.TOO_EXPENSIVE, value: "It's too expensive"},
  {type: Reason.TOO_COMPLICATED, value: 'The plugin was too complicated'},
  {type: Reason.DO_NOT_USE_IT, value: "I don't use it enough"},
  {type: Reason.JUST_TESTING, value: 'I was just testing it out'},
  {type: Reason.WORKFLOW, value: "It's not suited to my workflow"},
  {
    type: Reason.FEATURES,
    value: "It doesn't have the features I need",
  },
  {
    type: Reason.NOT_COMPATIBLE,
    value: "It's not compatible with my hardware",
  },
  {type: Reason.OTHER, value: 'Other'},
];

export const CancellationReasonModal: FunctionComponent<CancellationReasonModalProps> =
  ({onClose, onSubmit}) => {
    const [comment, setComment] = useState<string>('');
    const [formReason, setFormReason] = useState<Reason>(Reason.NONE);

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      onSubmit(formReason, comment);

      setFormReason(Reason.NONE);
      setComment('');

      e.preventDefault();
    };

    return (
      <Modal handleClose={onClose}>
        <Container>
          <div>
            <h2>Monthly payments cancelled</h2>
            <Spacer />
            We're sorry that you have chosen to cancel your monthly payments.
            <br />
            We really value any feedback as it helps us to improve our service.
          </div>
          <Spacer />
          <Paragraph>
            <b>Why did you choose to cancel your monthly payments?</b>
          </Paragraph>
          <form onSubmit={handleOnSubmit}>
            {cancellationReasons.map(({type, value}) => (
              <Option>
                <input
                  id={type}
                  key={value}
                  type="radio"
                  name="reason"
                  onChange={(e: React.ChangeEvent) => setFormReason(type)}
                />
                <Message htmlFor={type}>{value}</Message>
              </Option>
            ))}
            <Spacer />
            <Paragraph>
              <b>Please share any feedback you'd like us to take on board.</b>{' '}
              The more detail, the better.
            </Paragraph>
            <TextArea
              data-testid="cancellation-reason-comments"
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setComment(e.target.value)
              }
            />
            <Button>Send feedback</Button>
            <Spacer />
          </form>
        </Container>
      </Modal>
    );
  };
