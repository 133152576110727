import styled, {css} from 'styled-components';
import {Theme} from '../../theme/Theme';
import Img, {GatsbyImageProps} from 'gatsby-image';

const {space} = Theme;

export const Logo = styled.img`
  width: 100%;
  z-index: 1;
  transform: scale(0.4);
`;

export const Product = styled(Img)<GatsbyImageProps>`
  margin-top: ${space[5]}px;
  width: 100%;
  object-fit: contain;
  height: auto;
  transform: scale(0.72);
`;

export const Background = styled(Img)<GatsbyImageProps>`
  display: inline;
  width: 100%;
  height: 100%;
  transform: scale(1.5);
  img {
    filter: blur(12px);
  }
`;

interface ProductImageWrapperProps {
  greyScale: boolean;
}

export const ProductImageWrapper = styled.div<ProductImageWrapperProps>(
  ({greyScale}) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: black;
    align-items: center;
    justify-content: center;
    transition: filter 400ms ease-out;
    filter: grayscale(${greyScale ? 100 : 0}%);

    @media screen and (max-width: ${Theme.breakpoints[3]}px) {
      width: 50%;
    }

    @media screen and (max-width: ${Theme.breakpoints[1]}px) {
      width: 100%;
    }
  `
);
