import React, {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {Theme} from '../../theme/Theme';
import {User} from '@focusrite-novation/ampify-api';
import {getUserFromLocalStorage} from '../../utils/user-info';
import {UserInfo} from '../UserInfo/UserInfo';

const {breakpoints, space} = Theme;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${space[6]}px;
  align-items: center;
  h1 {
    flex-grow: 2;
  }
  @media screen and (max-width: ${breakpoints[1]}px) {
    flex-direction: column-reverse;
  }
  width: 100%;
`;

interface AccountPageHeaderProps {
  customerPortalBaseUrl: string;
}

export const AccountPageHeader: FunctionComponent<AccountPageHeaderProps> = ({
  customerPortalBaseUrl,
}) => {
  let user: User | null = null;
  try {
    user = getUserFromLocalStorage();
  } catch (error) {
    console.error(error);
  }

  return (
    <Row data-testid="account-page-header">
      <h1>My Account</h1>
      {user && (
        <UserInfo user={user} customerPortalBaseUrl={customerPortalBaseUrl} />
      )}
    </Row>
  );
};
