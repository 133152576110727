import {
  DisplayMessage,
  DisplayMessageType,
} from '@focusrite-novation/ampify-web-ui';
import {User} from '@focusrite-novation/ampify-api';
import {navigate} from 'gatsby';
import React, {FunctionComponent, useState, MouseEvent} from 'react';
import styled from 'styled-components';
import {Theme} from '../../theme/Theme';
import {sendAnalyticsEvent} from '../../utils/countly';
import {
  clearTokenFromLocalStorage,
  clearUserFromLocalStorage,
  getUserFromLocalStorage,
} from '../../utils/user-info';

const {breakpoints, space, colours, fontWeights, fontSizes} = Theme;

const LeftLeaningDiv = styled.div`
  text-align: right;
  min-width: 15rem;
  @media screen and (max-width: ${breakpoints[1]}px) {
    text-align: left;
    margin-bottom: ${space[4]}px;
  }
`;

const RedColouredLink = styled.a`
  color: ${colours.red};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes[3]}px;
`;

const BlueColouredLink = styled.a`
  color: ${colours.blue};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes[3]}px;
`;

const UserInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${breakpoints[0]}px) {
    flex-direction: column;
  }
`;

interface UserInfoProps {
  user: User;
  customerPortalBaseUrl: string;
}

export const UserInfo: FunctionComponent<UserInfoProps> = ({
  user,
  customerPortalBaseUrl,
}) => {
  const [error, setError] = useState('');

  const handleError = () => {
    setError('Unable to logout, please try again.');
    setTimeout(() => {
      setError('');
    }, 3500);
  };

  const handleLogout = async (
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault();
    try {
      const res = await fetch(`${customerPortalBaseUrl}/en/user/logout`, {
        credentials: 'include',
        redirect: 'manual',
      });

      if (res.status < 400) {
        clearUserFromLocalStorage();
        clearTokenFromLocalStorage();
        navigate('/');
      } else {
        handleError();
      }
    } catch (error) {
      handleError();
    }
  };

  const handleEditAccount = () => {
    sendAnalyticsEvent('Open account edit');
  };

  const getAccountUrl = () => {
    const user = getUserFromLocalStorage();
    if (!user?.id) {
      return `${customerPortalBaseUrl}/en/my-account`;
    }

    return `${customerPortalBaseUrl}/en/user/${user.id}/edit`;
  };

  return (
    <LeftLeaningDiv>
      <div data-testid="email-in-header-text">
        <UserInfoRow>
          <strong style={{paddingRight: '1rem'}}>Logged in as:</strong>
          <p> {user.email}</p>
        </UserInfoRow>
        <UserInfoRow>
          <BlueColouredLink
            href={getAccountUrl()}
            onClick={handleEditAccount}
            target="_blank"
            rel="noopener noreferrer"
          >
            Edit account
          </BlueColouredLink>
          <RedColouredLink
            href="/"
            onClick={handleLogout}
            data-testid="log-out-link"
          >
            Logout
          </RedColouredLink>
        </UserInfoRow>
      </div>
      {error && (
        <DisplayMessage testID="log-out-error" type={DisplayMessageType.ERROR}>
          {error}
        </DisplayMessage>
      )}
    </LeftLeaningDiv>
  );
};
