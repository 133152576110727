import React, {FunctionComponent, useEffect, useState} from 'react';
import {PaymentsApi} from '@focusrite-novation/ampify-api';
import {AxiosError} from 'axios';
import {graphql, navigate} from 'gatsby';
import {AccountPageHeader} from '../components/AccountPageHeader/AccountPageHeader';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {Gutters, GutterMaxWidth} from '../components/Spacing/Gutters';
import {OwnershipCard} from '../components/OwnershipCard';
import {OwnershipCards} from '../components/OwnershipCard/Owernship.styles';
import {
  mapProductsList,
  PluginOwnership,
  parseProps,
  fetchPlans,
  fetchPurchases,
} from '../lib/my-account';
import {createApi} from '../lib/api';
import * as loginUrl from '../utils/login-url';
import {ErrorMessage} from '../components/ErrorMessage';
import {Spacer} from '../components/Spacing/Spacer';
import {CancellationReasonModal} from '../components/CancellationReasonModal';
import {Reason} from '../components/CancellationReasonModal/CancellationReasonModal';
import {sendAnalyticsEvent, sendFeedbackEvent} from '../utils/countly';
import {
  clearTokenFromLocalStorage,
  clearUserFromLocalStorage,
} from '../utils/user-info';
import {MyAccountQuery} from './__generated__/MyAccountQuery';
import {OwnershipStatus} from '../components/OwnershipCard/OwnershipCard.models';

interface MyAccountPageProps {
  data: MyAccountQuery;
}

const MyAccountPage: FunctionComponent<MyAccountPageProps> = (props) => {
  const {
    footer,
    customerPortalBaseUrl,
    apiBaseUrl,
    oauthOptions,
    contentfulPlugins,
  } = parseProps(props.data);

  const plugins = contentfulPlugins.filter(({slug}) => 'fast-bundle' !== slug);
  const [userPluginOwnership, setUserPluginOwnership] = useState<
    PluginOwnership[]
  >(mapProductsList([], [], plugins, true));
  const [error, setError] = useState<AxiosError>();
  const [cancelSoftwareId, setCancelSoftwareId] = useState<string | null>(null);

  const handleSubscriptionAction = async (
    apiCall: (api: any) => Promise<void>
  ): Promise<boolean> => {
    try {
      const api = createApi(PaymentsApi, apiBaseUrl, oauthOptions);
      await apiCall(api);
    } catch (error) {
      // shouldn't be a user error so we need to log this for sentry
      console.error(error);
      return false;
    }

    await fetchPluginOwnership();

    return true;
  };

  const handleReactivateSubscription = async (
    planId: string
  ): Promise<boolean> => {
    return await handleSubscriptionAction(
      async (api) => await api.reactivateUserSubscriptionPlan(planId)
    );
  };

  const handleCancelSubscription = async (
    planId: string,
    softwareId: string
  ): Promise<boolean> => {
    setCancelSoftwareId(softwareId);
    return await handleSubscriptionAction(
      async (api) => await api.cancelUserSubscriptionPlan(planId)
    );
  };

  const fetchPluginOwnership = async () => {
    try {
      const api = createApi(PaymentsApi, apiBaseUrl, oauthOptions);

      const [userPlans, userPurchases] = await Promise.all([
        fetchPlans(api),
        fetchPurchases(api),
      ]);

      const ownership = mapProductsList(userPlans, userPurchases, plugins);

      setUserPluginOwnership(
        ownership.filter(({slug}) => slug !== 'fast-bundle')
      );
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.status >= 500) {
        setError(axiosError);
      }
      clearUserFromLocalStorage();
      clearTokenFromLocalStorage();
      navigate(loginUrl.myAccountUrl(customerPortalBaseUrl));
    }
  };

  const handleFeedbackSubmit = (type: Reason, comment: string) => {
    sendAnalyticsEvent('subscription-cancellation', {
      type,
      softwareId: cancelSoftwareId,
    });
    sendFeedbackEvent({
      comment: `[${cancelSoftwareId}]: ${comment || 'No comment'}`,
    });
    setCancelSoftwareId(null);
  };

  useEffect(() => {
    fetchPluginOwnership();
  }, []);

  return (
    <Layout
      groupedLinks={footer || []}
      customerPortalBaseUrl={customerPortalBaseUrl}
    >
      <SEO title="My Account" />
      <Gutters maxWidth={GutterMaxWidth.LARGE} paddingSides>
        <AccountPageHeader customerPortalBaseUrl={customerPortalBaseUrl} />
        <Spacer />
        {error ? (
          <ErrorMessage error={error} maxWidth={GutterMaxWidth.LARGE} />
        ) : (
          <OwnershipCards
            cardWidthPercentage={50}
            data-testid="ownership-card-container"
          >
            {userPluginOwnership.map((planOwnership, i) => (
              <OwnershipCard
                {...planOwnership}
                key={`plan-${i}`}
                testID={`${planOwnership.slug}-card`}
                onReactivateSubscription={handleReactivateSubscription}
                onCancelSubscription={handleCancelSubscription}
                onStartSubscription={() =>
                  navigate(`/products/${planOwnership.slug}`)
                }
              />
            ))}
          </OwnershipCards>
        )}
        {cancelSoftwareId && (
          <CancellationReasonModal
            onSubmit={handleFeedbackSubmit}
            onClose={() => setCancelSoftwareId(null)}
          />
        )}
      </Gutters>
    </Layout>
  );
};

export const pageQuery = graphql`
  query MyAccountQuery {
    site {
      siteMetadata {
        auth {
          customerPortalBaseUrl
        }
        ampifyApi {
          baseUrl
        }
        auth {
          client_id
          scope
        }
      }
    }
    allContentfulGroupedLinks(sort: {fields: createdAt, order: ASC}) {
      nodes {
        title
        links {
          label: text
          url: link
        }
      }
    }
    allContentfulProduct(sort: {order: ASC, fields: order}) {
      edges {
        node {
          slug
          softwareId
          title
          slideshow {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          logo: productLogo {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default MyAccountPage;
