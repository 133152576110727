import styled, {css} from 'styled-components';
import {Theme} from '../../theme/Theme';
import {OwnershipStatus} from './OwnershipCard.models';
import {ProductImageWrapper} from '../ProductImage/ProductImage.styles';

const {colours, space} = Theme;

const footerHeight = 240;
const imageHeight = footerHeight + 80;

interface StyledOwnershipCardProps {
  status: OwnershipStatus;
}

interface StatusStyles {
  backgroundColour: string;
  textColour: string;
}

const statusColour: Map<OwnershipStatus, StatusStyles> = new Map<
  OwnershipStatus,
  StatusStyles
>([
  [
    OwnershipStatus.EXPIRED,
    {backgroundColour: colours.red, textColour: colours.black},
  ],
  [
    OwnershipStatus.CANCELLED,
    {backgroundColour: colours.black, textColour: colours.white},
  ],
  [
    OwnershipStatus.SUBSCRIBED,
    {backgroundColour: colours.yellow, textColour: colours.black},
  ],
  [
    OwnershipStatus.PURCHASED,
    {backgroundColour: colours.blue, textColour: colours.black},
  ],
  [
    OwnershipStatus.OWNED,
    {backgroundColour: colours.blue, textColour: colours.white},
  ],
]);

export const StyledOwnershipCard = styled.div<StyledOwnershipCardProps>(
  ({status}) => {
    const style = statusColour.get(status);
    return css`
      display: flex;
      flex-direction: column;

      button {
        width: 100%;
        justify-content: center;
        margin-top: auto;
      }

      position: relative;
      background: ${colours.white};

      ol {
        list-style-type: none;
      }

      li {
        display: inline-block;
      }

      :before {
        z-index: 5;
        content: ' ${status === OwnershipStatus.NONE ||
        status === OwnershipStatus.LOADING
          ? ''
          : status}';
        position: absolute;
        height: 37px;
        width: 180px;
        margin: auto;
        top: ${space[4]}px;
        left: -${space[2]}px;
        color: ${style?.textColour};
        background: ${style?.backgroundColour};
        font-weight: bold;
        padding-top: 5px;
        padding-left: ${space[4]}px;
      }

      ${ProductImageWrapper} {
        height: ${imageHeight}px;
      }

      @media screen and (max-width: ${Theme.breakpoints[3]}px) {
        flex-direction: row;
      }

      @media screen and (max-width: ${Theme.breakpoints[1]}px) {
        flex-direction: column;

        button {
          margin-top: ${space[5]}px;
        }
      }
    `;
  }
);

export const OwnershipCardFooter = styled.div`
  overflow: hidden;
  position: relative;
  height: ${footerHeight}px;

  @media screen and (max-width: ${Theme.breakpoints[3]}px) {
    width: 50%;
    height: auto;
  }

  @media screen and (max-width: ${Theme.breakpoints[1]}px) {
    width: 100%;
  }
`;

export enum CardOverlayState {
  CONFIRM,
  LOADING,
  ERROR,
}

interface CardOverlayStateSettings {
  background: string;
  text: string;
}

const overlaySettings: Map<CardOverlayState, CardOverlayStateSettings> =
  new Map<CardOverlayState, CardOverlayStateSettings>([
    [
      CardOverlayState.CONFIRM,
      {
        background: colours.red,
        text: colours.white,
      },
    ],
    [
      CardOverlayState.ERROR,
      {
        background: colours.red,
        text: colours.white,
      },
    ],
    [
      CardOverlayState.LOADING,
      {
        background: colours.grey,
        text: colours.white,
      },
    ],
  ]);

interface CardOverlayProps {
  show: boolean;
  state: CardOverlayState;
}

export const CardOverlay = styled.div<CardOverlayProps>(({show, state}) => {
  const settings: CardOverlayStateSettings = overlaySettings.get(state) || {
    background: colours.red,
    text: colours.white,
  };

  return css`
    z-index: 4;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: ${show ? 0 : '-100%'};
    color: ${settings.text};
    background: ${settings.background};
    padding: ${space[5]}px;
    transition: bottom 300ms ease-out;
    height: ${footerHeight}px;
    width: 100%;
    justify-content: center;
    button {
      margin-top: ${space[3]}px;
    }
    svg {
      width: 100%;
    }

    @media screen and (max-width: ${Theme.breakpoints[3]}px) {
      height: 100%;
    }
  `;
});

interface OwnershipCardsProps {
  cardWidthPercentage: number;
}

export const OwnershipCards = styled.div<OwnershipCardsProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: ${Theme.breakpoints[3]}px) {
    flex-direction: column;
    justify-content: flex-start;
  }

  ${StyledOwnershipCard} {
    width: calc(${({cardWidthPercentage}) => cardWidthPercentage}% - 10px);
    margin-bottom: ${space[4]}px;
    font-size: ${Theme.fontSizes[2]}px;

    @media screen and (max-width: ${Theme.breakpoints[3]}px) {
      width: 100%;
    }

    :last-child {
      margin-right: 0;
    }
  }
`;
